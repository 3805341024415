import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import UsersPage from './components/Users'; 
import './index.css'
import BlogPost from "./components/BlogPost";
import Blog from "./components/Blog";
import ApplicationsPage from "./components/Applications";
import CompanyDetailsPage from "./components/ApplicationDetail";
import TradeZonesPage from "./components/Packages";
import LiveCompaniesPage from "./components/Companies";
import ImportCompany from "./components/ImportCompany";
import ServicesPage from "./components/Services";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
      <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/packages" element={<TradeZonesPage />} />
        <Route path="/applications" element={<ApplicationsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/import-company" element={<ImportCompany />} />
        <Route path="/companies" element={<LiveCompaniesPage />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/applications/:companyId" element={<CompanyDetailsPage />} />
        </Routes>

    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
