import { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import {
  BuildingOfficeIcon,
  CheckIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  DocumentTextIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const services = {
  visa: {
    visaAssistanceInside: {
      title: "Visa Assistance (Inside country)",
      description:
        "Includes entry permit & residency approval, change status, medical test, and more.",
    },
    visaAssistanceOutside: {
      title: "Visa Assistance (Outside country)",
      description:
        "Services for entry permit, medical test, biometrics, and Emirates ID delivery.",
    },
  },
  poBox: {
    sharedPOBox: {
      title: "Shared P.O Box",
      description:
        "A cost-effective solution for handling your business correspondence.",
    },
  },
  banking: {
    wioBank: {
      title: "Wio Bank Service",
      description:
        "Banking services tailored for businesses, including account management.",
    },
  },
};

const CompanyDetailsPage = () => {
  const location = useLocation();
  const [company, setCompany] = useState(location.state.company);

  const extraActivityCost =
    company.companyActivities && company.companyActivities.length > 3
      ? (company.companyActivities.length - 3) * 1000
      : 0;

  // Update total price calculation

  const establishmentCard = company.visas?.establishmentCard || 0;
  const licenseFee = company.visas?.licenseFee || 0;
  const visaCount = company.visas?.visa || 0;

  // Calculate addon costs and extract visa assistance add-ons
  const addonCosts = Object.values(company.addons || {}).reduce(
    (total, addon) => total + addon.cost * addon.quantity,
    0
  );

  const totalPrice =
    establishmentCard + licenseFee + visaCount + extraActivityCost + addonCosts;

  return (
    <div className="bg-white text-gray-900">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-xl font-semibold">Company Details</h1>
        {company && (
          <div className="mt-4">
            <div className="mx-auto my-1 mt-4 space-y-2 rounded-md bg-slate-100 p-8">
              <p className="secondary-slate pb-6 text-body">
                License Package Proposal Number: INSTA
                {company._id?.toUpperCase() || "N/A"}
              </p>

              {/* License Type and Company Names */}
              <div className="flex items-center justify-between rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div>
                  <span className="text-body leading-6 text-primary-default">
                    License Type
                  </span>
                  <div className="mt-2">
                    <div className="text-body font-semibold text-primary-dark">
                      {company.licenseType?.name || "N/A"}
                    </div>
                  </div>
                </div>
                <div>
                  <span className="text-body leading-6 text-primary-default">
                    Company Names
                  </span>
                  <ul className="mt-2 flex flex-wrap items-center gap-2">
                    {Object.entries(company.companyNames || {}).map(
                      ([key, names]) =>
                        names &&
                        (names.english || names.arabic) && (
                          <li key={key} className="text-body text-primary-dark">
                            <span className="inline-block rounded-full bg-primary-default px-3 py-1 text-black">
                              {names.english || ""}{" "}
                              {names.arabic && `/ ${names.arabic}`}
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>

              {/* Company Information */}
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="">
                  <span className="text-body leading-6 text-primary-default">
                    Company Information
                  </span>
                  <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className="flex items-center space-x-3">
                      <QuestionMarkCircleIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Company Type:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyDetails?.typeOfCompany || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <MapPinIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Location:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyDetails?.location || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <GlobeAltIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Trade Zone:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyDetails?.typeOfTradeZone || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <InformationCircleIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Business Details:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyType || "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <BuildingOfficeIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Type Of Office:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.typeOfOffice?.name || "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Shareholders & Share Capital */}
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <UserGroupIcon
                    className="mr-2 h-6 w-6 text-body text-primary-default"
                    aria-hidden="true"
                  />
                  <span className="text-body leading-6 text-primary-default">
                    Shareholders & Share Capital
                  </span>
                </div>
                <div>
                  <div className="mt-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {company.shareholderDetails?.shareholders?.map(
                      (shareholder, index) => (
                        <div
                          key={index}
                          className="rounded-lg border-2 border-primary-default bg-white p-4 shadow"
                        >
                          <div className="text-md font-semibold text-primary-default">
                            {shareholder.name || "N/A"}
                          </div>
                          <div className="mt-2 text-sm text-primary-dark">
                            Shares:{" "}
                            <span className="text-sm">
                              {shareholder.shares || "0"} shares
                            </span>
                          </div>
                          <div className="mt-1 text-sm text-primary-dark">
                            Address: {shareholder.addressLine1 || "N/A"},{" "}
                            {shareholder.cityState || "N/A"},{" "}
                            {shareholder.country || "N/A"}
                          </div>
                          <div className="mt-2 flex flex-wrap items-center gap-1">
                            {company.shareholderDetails?.directors?.some(
                              (director) => director.id === shareholder.id
                            ) && (
                              <span className="rounded-full bg-blue-100 px-2 py-1 text-xs text-blue-800">
                                Director
                              </span>
                            )}
                            {shareholder.id ===
                              company.shareholderDetails?.secretary?.id && (
                              <span className="rounded-full bg-green-100 px-2 py-1 text-xs text-green-800">
                                Secretary
                              </span>
                            )}
                            {shareholder.id ===
                              company.shareholderDetails?.generalManager
                                ?.id && (
                              <span className="rounded-full bg-red-100 px-2 py-1 text-xs text-red-800">
                                General Manager
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="flex items-center space-x-3">
                      <div>
                        <div className="text-body text-primary-dark">
                          Total Share Capital:
                          <span className="text-body font-semibold text-primary-dark">
                            {" "}
                            {company.shareholderDetails?.shareCapital?.totalValue?.toLocaleString() ||
                              "N/A"}{" "}
                            AED
                          </span>
                        </div>
                        <div className="text-body text-primary-dark">
                          Total Number of Shares:
                          <span className="text-body font-semibold text-primary-dark">
                            {" "}
                            {company.shareholderDetails?.shareCapital
                              ?.numberOfShares || "N/A"}
                          </span>
                        </div>
                        <div className="text-body text-primary-dark">
                          Value per Share:
                          <span className="text-body font-semibold text-primary-dark">
                            {" "}
                            {company.shareholderDetails?.shareCapital
                              ?.valuePerShare || "N/A"}{" "}
                            AED
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* License Package Details */}
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <UserIcon
                    className="mr-2 h-6 w-6 text-body text-primary-default"
                    aria-hidden="true"
                  />
                  <span className="text-body leading-6 text-primary-default">
                    License Package Details
                  </span>
                </div>
                <div className="mt-4">
                  <div
                    className={`relative col-span-1 h-[20rem] w-[24rem] transform cursor-pointer rounded-xl  bg-[#1C1C83] text-white shadow-sm
               ring-2 ring-blue-600 transition-transform duration-200`}
                  >
                    <div className="mt-2 flex h-full flex-col justify-between p-6">
                      <div className="flex items-center justify-between">
                        <span className="text-3xl font-semibold">
                          AED {licenseFee?.toLocaleString() || "0"}
                        </span>
                      </div>
                      <span className="mt-2 text-body ">
                        {visaCount || "0"} Visa License
                      </span>
                      <ul className="mb-6 space-y-2 text-body">
                        <li>
                          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                            <CheckIcon className="h-4 w-4" />
                          </span>{" "}
                          Free Virtual Office
                        </li>
                        <li>
                          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                            <CheckIcon className="h-4 w-4" />
                          </span>{" "}
                          Visa Provided with License: {visaCount || "0"}
                        </li>
                        <li>
                          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                            <CheckIcon className="h-4 w-4" />
                          </span>{" "}
                          License Fee: AED {licenseFee?.toLocaleString() || "0"}
                        </li>
                        <li>
                          <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                            <CheckIcon className="h-4 w-4" />
                          </span>
                          {establishmentCard
                            ? ` Establishment Card: AED ${establishmentCard.toLocaleString()}`
                            : " Establishment Card: N/A"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add-ons */}
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <DocumentTextIcon
                    className="mr-2 h-6 w-6 text-body text-primary-default"
                    aria-hidden="true"
                  />
                  <span className="text-body leading-6 text-primary-default">
                    Add ons
                  </span>
                </div>
                <div className="mt-4">
                  {Object.entries(company.addons || {}).map(([key, addon]) => {
                    if (addon?.selected) {
                      const { mainService, package: pkg } = addon;
                      const serviceDetails = services[mainService]?.[pkg];
                      return (
                        <div
                          key={key}
                          className="mb-4 overflow-hidden rounded-lg bg-white shadow"
                        >
                          <div className="bg-[#144EFF] p-4">
                            <h3 className="text-lg font-semibold text-white">
                              {serviceDetails?.title || "N/A"}{" "}
                              {addon.vip && (
                                <span className="ml-2 inline-block rounded-full bg-amber-600 px-3 py-1 text-xs font-medium text-white">
                                  VIP
                                </span>
                              )}
                            </h3>
                            <p className="text-sm text-gray-200">
                              {serviceDetails?.description || "N/A"}
                            </p>
                          </div>
                          <div className="border-t border-gray-200 bg-white px-4 py-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="text-md font-medium">
                                <span className="text-gray-800">Quantity:</span>
                                <span className="ml-1 text-primary-default">
                                  {addon.quantity || "0"}
                                </span>
                              </div>
                              <div className="text-md font-medium">
                                <span className="text-gray-800">
                                  Add-on Cost:
                                </span>
                                <span className="ml-1 text-primary-default">
                                  AED {addon.cost?.toLocaleString() || "0"}
                                </span>
                              </div>
                              <div className="text-md font-medium">
                                <span className="text-gray-800">
                                  Total Cost:
                                </span>
                                <span className="ml-1 text-primary-default">
                                  AED{" "}
                                  {(
                                    (addon.quantity || 0) * (addon.cost || 0)
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>

              {/* Company Details */}
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <BuildingOfficeIcon
                    className="mr-2 h-6 w-6 text-body text-primary-default"
                    aria-hidden="true"
                  />
                  <span className="text-body leading-6 text-primary-default">
                    Company Details
                  </span>
                </div>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  <div>
                    <span className="font-semibold">Company Mobile:</span>{" "}
                    {company.companyDetails?.companyMobile || "N/A"}
                  </div>
                  <div>
                    <span className="font-semibold">Company Email:</span>{" "}
                    {company.companyDetails?.companyEmail || "N/A"}
                  </div>
                  <div>
                    <span className="font-semibold">Shareholder Name:</span>{" "}
                    {company.shareholderName || "N/A"}
                  </div>
                  <div>
                    <span className="font-semibold">Status:</span>{" "}
                    {company.status || "N/A"}
                  </div>
                  <div>
                    <span className="font-semibold">Stage:</span>{" "}
                    {company.stage || "N/A"}
                  </div>
                  <div>
                    <span className="font-semibold">Active:</span>{" "}
                    {company.isActive ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <DocumentTextIcon
                    className="mr-2 h-6 w-6 text-body text-primary-default"
                    aria-hidden="true"
                  />
                  <span className="text-body leading-6 text-primary-default">
                    Documents
                  </span>
                </div>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {Object.entries(company.documents || {}).map(([key, url]) => (
                    <div key={key}>
                      <span className="font-semibold">
                        {key
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                        :
                      </span>
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 text-blue-600 hover:underline"
                      >
                        View Document
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <span className="text-body leading-6 text-primary-default">
                  Company Names
                </span>
                <ul className="mt-2 flex flex-wrap items-center gap-2">
                  {Object.entries(company.companyNames || {}).map(
                    ([key, names]) => (
                      <li key={key} className="text-body text-primary-dark">
                        <span className="inline-block rounded-full bg-primary-default px-3 py-1 text-black">
                          {names.english} {names.arabic && `/ ${names.arabic}`}
                        </span>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="flex items-center">
                  <UserIcon
                    className="mr-2 h-6 w-6 text-body text-primary-default"
                    aria-hidden="true"
                  />
                  <span className="text-body leading-6 text-primary-default">
                    Ultimate Beneficial Owner (UBO)
                  </span>
                </div>
                <div className="mt-4">
                  <div>
                    <span className="font-semibold">UBO Option:</span>{" "}
                    {company.ubo?.uboOption || "N/A"}
                  </div>
                  {company.ubo?.uboMembersList &&
                    company.ubo.uboMembersList.length > 0 && (
                      <div className="mt-2">
                        <span className="font-semibold">UBO Members:</span>
                        <ul className="list-disc pl-5">
                          {company.ubo.uboMembersList.map((member, index) => (
                            <li key={index}>{member}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyDetailsPage;
