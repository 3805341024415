import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import { getAuthHeader } from "./Blog";

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const limit = 10;

  useEffect(() => {
    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://api.instacorp.ae/api/admin/data/services?page=${currentPage}&limit=${limit}`,
          {
            headers: getAuthHeader(),
          }
        );
        setServices(response.data.services);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
      setIsLoading(false);
    };

    fetchServices();
  }, [currentPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const handleRowClick = (service) => {
    setSelectedService(service);
  };

  const closeDetails = () => {
    setSelectedService(null);
  };

  const markAsComplete = async (serviceId) => {
    try {
      const response = await axios.put(
        `https://api.instacorp.ae/api/admin/data/services/${serviceId}/complete`,
        {},
        {
          headers: getAuthHeader(),
        }
      );
      alert(response.data.message || "Service marked as complete successfully");

      // Refresh the services list after marking as complete
      setServices((prevServices) =>
        prevServices.map((service) =>
          service._id === serviceId ? { ...service, status: "completed" } : service
        )
      );
      closeDetails();
    } catch (error) {
      console.error("Error marking service as complete:", error);
      alert("Failed to mark service as complete");
    }
  };

  const renderDocuments = (documents) => {
    if (!documents || Object.keys(documents).length === 0) {
      return <p>No documents available</p>;
    }
    return (
      <ul className="list-disc list-inside">
        {Object.entries(documents).map(([key, url]) => (
          <li key={key}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline"
            >
              {key.replace(/([A-Z])/g, " $1").trim()} (View)
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <Header />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            Services
          </h1>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Service Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Company/User
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Request Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Payment Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" className="text-center py-10">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    services.map((service) => (
                      <tr
                        key={service._id}
                        className="cursor-pointer"
                        onClick={() => handleRowClick(service)}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {service.serviceType} (
                          {service.companyDetails?.companyNames?.first?.english ||
                            service.userDetails?.first_name})
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {service.companyDetails
                            ? service.companyDetails.companyDetails.companyEmail
                            : service.userDetails?.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(service.requestDate)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                              service.status === "processing"
                                ? "bg-yellow-100 text-yellow-800"
                                : service.status === "completed"
                                ? "bg-green-100 text-green-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                          >
                            {service.status || "N/A"}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                              service.paymentStatus === "complete"
                                ? "bg-green-100 text-green-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                          >
                            {service.paymentStatus || "N/A"}
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-4 mb-12">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
          >
            Previous
          </button>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
          >
            Next
          </button>
        </div>
      </div>

      {selectedService && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-lg w-full">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">
              Service Details
            </h2>
            <p>
              <strong>Service Type:</strong> {selectedService.serviceType}
            </p>
            <p>
              <strong>Request Date:</strong>{" "}
              {formatDate(selectedService.requestDate)}
            </p>
            <p>
              <strong>Company Name:</strong>{" "}
              {selectedService.companyDetails?.companyNames?.first?.english ||
                "N/A"}
            </p>
            <p>
              <strong>User:</strong> {selectedService.userDetails?.first_name}{" "}
              {selectedService.userDetails?.last_name}
            </p>
            <p>
              <strong>Delivery Address:</strong>{" "}
              {selectedService.deliveryAddress || "N/A"}
            </p>
            <p>
              <strong>Email:</strong>{" "}
              {selectedService.companyDetails?.companyDetails?.companyEmail ||
                selectedService.userDetails?.email}
            </p>
            <p>
              <strong>Documents:</strong>
            </p>
            {renderDocuments(selectedService.documents)}
            <p>
              <strong>Company Documents:</strong>
            </p>
            {renderDocuments(selectedService.companyDetails?.documents)}
            <button
              onClick={() => markAsComplete(selectedService._id)}
              className="mt-4 px-4 py-2 text-sm text-white bg-green-600 rounded-md hover:bg-green-700"
              disabled={selectedService.status === "completed"}
            >
              Mark as Complete
            </button>
            <button
              onClick={closeDetails}
              className="mt-4 px-4 py-2 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 ml-2"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center space-x-1">
      {[...Array(totalPages).keys()].map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number + 1)}
          className={`px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md ${
            currentPage === number + 1 ? "bg-gray-200" : "hover:bg-gray-100"
          }`}
        >
          {number + 1}
        </button>
      ))}
    </div>
  );
};

export default ServicesPage;
